import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoginDevise from '../components/LoginDevise'

const LoginPage = ({ location }) => {

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/login",
      "@type": "webpage",
      "name": "Hubble Contacts Account Login | Customer Portal",
      "description": "Login to your Hubble account customer portal to access details on your subscriptions, update your preferences, view general account information about your contacts and glasses, and more.",
      "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
      }
    }
  ]

  const urlParams = (typeof window !== 'undefined') ? new URLSearchParams(window?.location.search) : null
  const declinedPaymentCustomer = urlParams?.get('update_card') === 'true'
  const declineCode = urlParams?.get('decline')

  return (
    <Layout>
      <SEO title="Hubble Contacts Account Login | Customer Portal"
        description="Login to your Hubble account customer portal to access details on your subscriptions, update your preferences, view general account information about your contacts and glasses, and more."
        schemaMarkup={schemaMarkup} />
      <LoginDevise backLink={location?.state?.link} declinedPaymentCustomer={declinedPaymentCustomer} declineCode={declineCode} />
    </Layout>
  )
}

export default LoginPage
