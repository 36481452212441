import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import { useCookies } from 'react-cookie';
import './style.scss'
import { navigate } from 'gatsby-link';
import { Spinner } from 'react-bootstrap';
import CustomerContext from '../../context/customerContext';


const LoginDevise = ({ backLink, declinedPaymentCustomer, declineCode }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['user-jwt']);
  const user_token = cookies['user-jwt']
  const { stripeCustomer, setStripeCustomer} = useContext(CustomerContext)

  let is_recharge = undefined;
  let impersonate = undefined;
  if (typeof window !== 'undefined') {
    // url param to test recharge users in dev/staging
    const query = new URLSearchParams(window?.location?.search)
    is_recharge = query?.get('recharge') || undefined
    impersonate = query?.get('impersonate') || undefined
  }

  const login = async (e) => {
    e.preventDefault();
    setLoading(true)
    setError(null)

    const url = `${process.env.GATSBY_API_URL}/api/auth/sign_in`
    const authReq = await axios.post(url, {auth: {email, password, is_recharge, impersonate} }).catch(handleLoginError)

    const loginType = authReq?.data?.type;
    if (loginType == 'stripe') handleStripeLogin(authReq)
    if (loginType == 'recharge') handleRechargeLogin(authReq)
  }

  const handleLoginError = (error) => {
    const respError = error?.response?.data?.errors.join(' ')
    const errorText = respError || 'There was a problem with your login, please check your email and password'
    setError(errorText)
    setLoading(false)
  }

  const handleStripeLogin = (authReq) => {
    const stripeJwt = authReq?.data?.token
    setCookie('user-jwt', stripeJwt, {maxAge: 1209600, path: "/"} )
    const headers = {headers: { Authorization: `Bearer ${stripeJwt}` }}

    axios.get(`${process.env.GATSBY_API_URL_STRIPE}/api/v1/stripe/customers`, headers)
    .then((customerResponse) => {
      setStripeCustomer({...customerResponse.data, type: "stripe"})
      localStorage.setItem('customer_data', JSON.stringify({...customerResponse.data, type: "stripe"}))
      declinedPaymentCustomer && localStorage.setItem('declinedPaymentCustomer', JSON.stringify(declinedPaymentCustomer))
      declineCode && localStorage.setItem('declineCode', declineCode)

      if (typeof window !== "undefined") {
        if(window.gtag !== null) {
          window.dataLayer.push({'user_id': authReq?.data?.user?.id})
          window.dataLayer.push({event: "login", method: "email"})
        }
      }

      axios.get(`${process.env.GATSBY_API_URL_STRIPE}/api/v1/stripe/customers/orders`, headers)
      .then((response) => {
        const orders = response.data.orders
        const customerWithOrders = { ...customerResponse.data, type: "stripe", orders: [...orders] }
        setStripeCustomer(customerWithOrders)
        localStorage.setItem('customer_data', JSON.stringify(customerWithOrders))
      })
      .catch((error) => {
        console.log(error)
      })
    }).then(() => {
      setLoading(false)
      navigate(backLink || "/customer-portal/")
    })
  }

  const handleRechargeLogin = (authReq) => {
    const user_token = authReq?.data?.user_token
    setCookie('user-jwt', user_token, {maxAge: 1209600, path: "/"} )

    axios.get(`${process.env.GATSBY_API_URL}/api/customer_data`, { params: { user_token } })
    .then((response) => {
      if (typeof window !== "undefined") {
        if (window.gtag !== null) {
          window.dataLayer.push({'user_id': user_token})
          window.dataLayer.push({event: "login", method: "email"})
        }
      }
      setStripeCustomer({...response.data.data, type: "recharge"})
      localStorage.setItem('customer_data', JSON.stringify({...response.data.data, type: "recharge"}))
      axios.get(`${process.env.GATSBY_API_URL}/api/customer_subscription`, { params: { user_token } })
      .then((response) => {
        localStorage.setItem('upcoming_order', JSON.stringify(response.data.data))
      })
    }).then(() => {
      setLoading(false)
      navigate(backLink || "/customer-portal/")
    })
  }

  useEffect(()=> {
    (user_token && stripeCustomer.id && !loading) && navigate(backLink || "/customer-portal/")
    if (declinedPaymentCustomer) localStorage.setItem('declinedPaymentCustomer', JSON.stringify(declinedPaymentCustomer))
  }, [user_token, stripeCustomer])

  return (
    <main className="login-page main-content grid-section" role="main">
      <div className="login-wrapper">
        <form method="post">
          <div className="container">
            <div className="grid__item title one-whole text-center">
              <h1 className="heading">Sign in</h1>
            </div>
            <div className="input-container">
            <label htmlFor="CustomerEmail" className="label">
              Email Address
            </label>
            <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} id="CustomerEmail" className="input-full" placeholder="Enter your email address" autoCorrect="off" autoCapitalize="off" autoFocus="" />
            <label htmlFor="CustomerPassword" className="label">
              Password
            </label>
            <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} id="CustomerPassword" className="input-full" placeholder="Enter your Password" />

            { error &&
              <p className="login-error">{error}</p>
            }

            <a className="forgot-password" href="/forgot-password" id="RecoverPassword">
              Forgot Your Password?
            </a>

            <div className='login-button' onClick={(e) => login(e)}>
              <>{!loading ? "Sign In" : <Spinner />}</>
            </div>
            </div>
          </div>
        </form>
      </div>
    </main >
  )
}

export default LoginDevise
